import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-12 gap-4" }
const _hoisted_2 = { class: "col-span-3" }
const _hoisted_3 = { class: "col-span-3" }
const _hoisted_4 = { class: "col-span-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vxe_option = _resolveComponent("vxe-option")!
  const _component_vxe_select = _resolveComponent("vxe-select")!
  const _component_vxe_input = _resolveComponent("vxe-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vxe_select, {
        modelValue: _ctx.currentValue.City,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentValue.City) = $event)),
        transfer: "",
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeCity($event.value))),
        placeholder: "選擇縣市"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cities, (item, index) => {
            return (_openBlock(), _createBlock(_component_vxe_option, {
              label: item,
              value: item,
              key: 'city-' + index
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_vxe_select, {
        modelValue: _ctx.currentValue.DistrictDisplay,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentValue.DistrictDisplay) = $event)),
        transfer: "",
        onChange: _ctx.updateValue,
        placeholder: "選擇區域"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.districts, (item, index) => {
            return (_openBlock(), _createBlock(_component_vxe_option, {
              label: item,
              value: item,
              key: 'district-' + index
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "onChange"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_vxe_input, {
        modelValue: _ctx.currentValue.Line,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentValue.Line) = $event)),
        onChange: _ctx.updateValue,
        placeholder: "填寫地址"
      }, null, 8, ["modelValue", "onChange"])
    ])
  ]))
}